'use strict';

const {
    trapFocus,
} = require('../../../../../../../../app_custom_mufe/cartridges/app_custom_mufe/cartridge/client/default/js/components/util');

function colorContrast() {
    $('.high-contrast-image').each(function () {
        var element = $(this);

        if (element.is('img')) {
            element.wrap(
                '<div class="image-contrast-container dark-overlay"></div>'
            );
        } else {
            element.children().addClass('overlay-children-position');
            element.addClass('dark-overlay');
        }
    });
}

function updateProfileModal() {
    window.onload = function () {
        var queryString = window.location.search;
        var isUpdated = queryString.includes('?updated=true');
        if (isUpdated === true) {
            if (!$('.js-profile-update-revamp').length) {
                $('.modal-background').show();
            }
            $('.js-profile-update').addClass('active');
            $('.js-close-updated-modal').focus();
            trapFocus($('.js-profile-update')[0]);
        }
    };
    $('.js-profile-update').on('click', function (e) {
        if (e.target.closest('.js-close-updated-modal')) {
            $(this).removeClass('active');
            $('.modal-background').hide();

            // Update the query parameter to 'false'
            var currentUrl = window.location.href;
            var updatedUrl = currentUrl.replace(
                '?updated=true',
                '?updated=false'
            );
            history.replaceState(null, '', updatedUrl);
        }
    });
}
function handleKeyboardFocus() {
    $('.form-control').on('keydown', function (event) {
        if (event.key !== 'Tab') {
            $(this).addClass('is-typing');
        } else {
            $('.form-control').removeClass('is-typing');
        }
    });
}

function handleMouseFocus() {
    $('.form-control').on('focus', function () {
        $('.form-control').not($(this)).removeClass('is-typing');
    });

    $('.form-control').on('focusout', function () {
        $('.form-control').removeClass('is-typing');
    });
}

function addAttributesToSubtitles() {
    $('.longdescription-subtitle')
        .attr('role', 'heading')
        .attr('aria-level', '3');
    $('.our-pro-title').attr('role', 'heading').attr('aria-level', '2');
}

module.exports = function () {
    colorContrast();
    handleKeyboardFocus();
    handleMouseFocus();
    addAttributesToSubtitles();
    updateProfileModal();
};
