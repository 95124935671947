'use strict';

module.exports = function () {
    var skipColors = $('[id^=skip-color]');
    var circles = skipColors.find('path[role="radio"]');
    var lastSkipLink = $('[href="#skip-color5"]');
    var lipstickButton = $('.js-rouge-shade-focus');

    // handle first tab press
    lastSkipLink.on('keydown', function (event) {
        var key = event.key;
        if (event.shiftKey && key === 'Tab') {
            $(this).prev().trigger('focus');
        } else if (key === 'Tab') {
            circles.filter('.active').prev().trigger('focus');
        }
    });

    // handle last tab press
    lipstickButton.on('keydown', function (event) {
        if (event.shiftKey && event.key === 'Tab') {
            circles.filter('.active').next().trigger('focus');
        }
    });

    // handle circles
    circles.each(function (index) {
        $(this).on('click', function () {
            $(this).attr('aria-checked', 'true');
            circles.each(function (i) {
                if (index !== i) {
                    $(this).removeAttr('aria-checked');
                }
            });
        });

        // Add keyboard support
        $(this).on('keydown', function (event) {
            event.preventDefault();
            var key = event.key;
            var currentIndex = circles.index($(this));
            var lastIndex = circles.length - 1;
            switch (key) {
                case 'ArrowLeft':
                case 'ArrowUp':
                    // Select previous circle
                    if (currentIndex > 0) {
                        $(circles[currentIndex - 1])
                            .trigger('click')
                            .trigger('focus');
                    } else {
                        $(circles[lastIndex]).trigger('click').trigger('focus');
                    }
                    break;
                case 'ArrowRight':
                case 'ArrowDown':
                    // Select next circle
                    if (currentIndex < lastIndex) {
                        $(circles[currentIndex + 1])
                            .trigger('click')
                            .trigger('focus');
                    } else {
                        $(circles[0]).trigger('click').trigger('focus');
                    }
                    break;
                case 'Tab':
                    // Focus next skip-color
                    event.preventDefault();
                    if (event.shiftKey) {
                        // focus to the previous element
                        lastSkipLink.trigger('focus');
                    } else {
                        lipstickButton.trigger('focus');
                    }
                    break;
            }
        });
    });

    $('.aqua-resist-shade-selector-button').attr('tabindex', '-1');
    $('.aqua-resist-shade-selector-button').attr('role', 'radio');
    $('.aqua-resist-shade-selector-button.active').attr('tabindex', '0');
    $('.aqua-resist-shade-selector-button').on('keydown', function (event) {
        var key = event.key;
        var currentIndex = $('.aqua-resist-shade-selector-button').index(
            $(this)
        );
        var lastIndex = $('.aqua-resist-shade-selector-button').length - 1;

        if (key === 'ArrowLeft' && currentIndex > 0) {
            var prevButton = $('.aqua-resist-shade-selector-button').eq(
                currentIndex - 1
            );
            prevButton.focus().attr('tabindex', '0').trigger('click');
            $(this).attr('tabindex', '-1');
        } else if (key === 'ArrowRight' && currentIndex < lastIndex) {
            var nextButton = $('.aqua-resist-shade-selector-button').eq(
                currentIndex + 1
            );
            nextButton.focus().attr('tabindex', '0').trigger('click');
            $(this).attr('tabindex', '-1');
        }
    });
};
