'use strict';

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

/**
    * Make the call to Handle the SFCC Session tracking in function of the cookie Optanon COnsent groups
    * @param sessionTrackingAllowed boolean value of the permission of the session tracking
    */
function changeSessionTracking(sessionTrackingAllowed) {
    var changeSessionTrackingURL = $('#changeSessionTrackingURL').val();
    getJson({
        url: changeSessionTrackingURL.toString(),
        data: {
            sessionTrackingAllowed: sessionTrackingAllowed,
        }
    });
}

/**
* Parse the Optanon Consent cookie value
* @param value string to parse
* @param separator1 first separator
* @param separator2 second separator
* @returns an object of the parsed Optanon Consent
*/
function parseCookieOptanonConsentValue(value, separator1, separator2) {
    if (!value || !separator1 || !separator2) {
        return {};
    }

    var object = {};
    var a = value.split(separator1);
    for (var i = 0; i < a.length; i++) {
        var b = a[i].split(separator2);
        object[b[0]] = b[1];
    }

    return object;
}

/**
 * @function
 * @description Ajax request to get json response
 * @param {Boolean} async  Asynchronous or not
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 */
function getJson(options) {
    var currentRequests = [];
    options.url = toAbsoluteUrl(options.url);
    // return if no url exists or url matches a current request
    if (!options.url || currentRequests[options.url]) {
        return;
    }

    currentRequests[options.url] = true;

    // make the server call
    $.ajax({
        dataType: 'json',
        url: options.url,
        async: (typeof options.async === 'undefined' || options.async === null) ? true : options.async,
        data: options.data || {}
    })
    // success
        .done(function (response) {
            if (options.callback) {
                options.callback(response);
            }
        })
    // executed on success or fail
        .always(function () {
        // remove current request from hash
            if (currentRequests[options.url]) {
                delete currentRequests[options.url];
            }
        });
}

/**
 * @function
 * @description
 * @param {String} url
 */
function toAbsoluteUrl(url) {
    if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
        url = '/' + url;
    }
    return url;
}

/**
* handle the Optanon Wrapper Event.
* It will retrieve the Optanon Consent Informations and update the session tracking if needed.
*/
function handleOptanonWrapperEvent() {
    var optanonCookieGroupsToDisableSFCCTracking = 'C0002,C0003,C0004';

    var cookieOptanonConsent = getCookie('OptanonConsent');

    if (cookieOptanonConsent) {
        var cookieOptanonConsentInfos = parseCookieOptanonConsentValue(cookieOptanonConsent, '&', '=');

        if (cookieOptanonConsentInfos.groups) {
            var cookieOptanonConsentGroupsArray = parseCookieOptanonConsentValue(cookieOptanonConsentInfos.groups, ',', ':');
            var groupsToDisableSFCCTrackingArray = optanonCookieGroupsToDisableSFCCTracking.split(',');
            var groupsToEnableForSession = [].filter.call(groupsToDisableSFCCTrackingArray, function (optanonGroup) {
                return cookieOptanonConsentGroupsArray[optanonGroup] === '1';
            });

            // If customer accepts at least 1 secondary group of cookies (C0002,C0003 or C0004), we consider that he accepts SFCC tracking and change session value accordingly.
            var shouldSessionTrackingAllowed = (groupsToEnableForSession && groupsToEnableForSession.length > 0);
            var currentSessionTrackingAllowed = (getCookie('dw_dnt') === '0');

            if (currentSessionTrackingAllowed != shouldSessionTrackingAllowed) {
                changeSessionTracking(shouldSessionTrackingAllowed);
            }
        }
    }
}

module.exports = function () {
    if ($('.valid-cookie-warning').length > 0) {
        var previousSessionID = window.localStorage.getItem('previousSid');
        var currentSessionID = getCookie('sid');
        if (!previousSessionID && currentSessionID) {
            // When a user first time visit the home page,
            // set the previousSessionID to currentSessionID
            // and Show the cookie alert
            previousSessionID = currentSessionID;
            window.localStorage.setItem('previousSid', previousSessionID);
            $('.cookie-warning-messaging').show();
        } else if (previousSessionID && previousSessionID === currentSessionID) {
            // Hide the cookie alert if user is in the same session
            $('.cookie-warning-messaging').hide();
        } else {
            // Clear the previousSessionID from localStorage
            // when user session is changed or expired
            previousSessionID = '';
            window.localStorage.removeItem('previousSid');
        }
    }

    $(document).on('optanon.wrapper.event', handleOptanonWrapperEvent);
};
