'use strict';

module.exports = function () {
    var stickyWrapper = $('#pdp-sticky-wrapper');

    function findFocusableElements($element) {
        return $element.find(':input, a, [tabindex]');
    }

    $(window).scroll(function () {
        var focusableElements = findFocusableElements(stickyWrapper);
        if (stickyWrapper.hasClass('fixed')) {
            focusableElements.attr('tabindex', '-1');
        } else {
            focusableElements.removeAttr('tabindex');
        }
    });
};
