'use strict';
module.exports = function () {
    var sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

    sizes.forEach(function (size) {
        var selector = '.collapsible-' + size + ' .title, .collapsible-' + size + ' .card-header';
        $('body').on('click', selector, function () {
            $(this).parents('.collapsible-' + size).toggleClass('active').siblings('.active').removeClass('active');
            $(this).toggleAttrVal('aria-expanded', true, false);
        });
    });
    sizes.forEach(function (size) {
        var selector2 = '.collapsiblefade-' + size + ' .title, .collapsiblefade-' + size + ' .title2';
        $('body').on('click', selector2, function (e) {
            e.preventDefault();
            var windowSize = $(window).width();
            var sizescrean = 0;
            switch (size) { 
                case 'sm': 
                    sizescrean = 544;
                    break;
                case 'md': 
                    sizescrean = 769;
                    break;
                case 'lg': 
                    sizescrean = 992;
                    break;
                case 'xl': 
                    sizescrean = 1200;
                    break;
                default:
                    sizescrean = 1440;
            }
            if (windowSize < sizescrean){
                $(this).parents('.collapsiblefade-' + size).children('.content').slideToggle('slow');
                $(this).closest('.collapsiblefade-' + size).toggleClass('active');
                $(this).toggleAttrVal('aria-expanded', true, false);
                $(this).find('.title').toggleAttrVal('aria-expanded', true, false);
                $('.pdp-image-slider-container').slick('setPosition');
            }else{
                $(this).closest('.collapsiblefade-' + size).toggleClass('active');
            }
        });
    });

    // jquery toggle just an attribute
    $.fn.toggleAttrVal = function(attr, val1, val2) {
        var test = $(this).attr(attr);
        if(test){
            if (  test.toString() === val1.toString()) {
                $(this).attr(attr, val2);
                return this;
            } else if ( test.toString() === val2.toString()){
                $(this).attr(attr, val1);
                return this;
            }
        }

        // default false if not working
        $(this).attr(attr, 'false');
        return this;
    };
};

