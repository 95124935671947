'use strict';

/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner.
 *                            Pass body to block the whole page.
 */
function addSpinner($target) {

    var loadingADAtext = $('.loading-text-replace') ? $('.loading-text-replace').text() : "";
    var $veil = $('<div class="veil"><div class="underlay"><span role="status" aria-live="polite" id="busy" class="sr-only"><span id="busy" class="sr-only">' + loadingADAtext + '</span></span></div></div>');
    $veil.append('<div class="holder"></div><div class="new-loader"></div>');
    if ($target.get(0).tagName === 'IMG') {
        $target.after($veil);
        $veil.css({
            width: $target.width(),
            height: $target.height()
        });
        if ($target.parent().css('position') === 'static') {
            $target.parent().css('position', 'relative');
        }
    } else {
        $target.append($veil);
        if ($target.css('position') === 'static') {
            $target.parent().css('position', 'relative');
            $target.parent().addClass('veiled');

            if ($target.get(0).tagName === 'BUTTON') {
                $target.parent().addClass('button-loader');
            }
            //  target.before("<p>loader</p>");
        }
        if ($target.get(0).tagName === 'BODY') {
            $veil.find('.spinner').css('position', 'fixed');
        }
    }
    
    $veil.click(function (e) {
        e.stopPropagation();
    });
}

/**
 * Remove existing spinner
 * @param  {element} $veil - jQuery pointer to the veil element
 */
function removeSpinner($veil) {
    if ($veil.find('.button').length) {
        $veil.removeClass('button-loader');
        $veil.removeClass('veiled');
        $veil.addClass('veiled123');
        if ($veil.find('.card-footer').length)
            $veil.find('.card-footer').removeClass('veiled');
        $veil = $('.veil');
    } else {
        if ($veil.parent().hasClass('veiled')) {
            $veil.parent().css('position', '');
            $veil.parent().removeClass('veiled');
        }
    }
    $veil.off('click');
    $veil.remove();
}

// element level spinner:
$.fn.spinner = function () {
    var $element = $(this);
    var Fn = function () {
        this.start = function () {
            if ($element.length) {
                if ($element.find('.button').length) {
                    addSpinner($element.find('.button'));
                } else {
                    addSpinner($element);
                }
            }
        };
        this.stop = function () {
            if ($element.length) {
                if ($element.find('.button').length) {
                    removeSpinner($element);
                } else {
                    var $veil = $('.veil');
                    removeSpinner($veil);
                }
            }
        };
    };
    return new Fn();
};

// page-level spinner:
$.spinner = function () {
    var Fn = function () {
        this.start = function () {
            if ($('#show-more').length) {
                addSpinner($('#show-more-loader'));
            } else {
                addSpinner($('body'));
            }

        };
        this.stop = function () {
            removeSpinner($('.veil'));
        };
    };
    return new Fn();
};