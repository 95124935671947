'use strict';

const libPostalCodes = require('../../../../../../../../app_custom_mufe/cartridges/app_custom_mufe/cartridge/config/postalCodes');
const statesCodes = require('../../../../../../../../app_custom_mufe/cartridges/app_custom_mufe/cartridge/config/statesCodes');

function init() {
    // global for all forms
    $('select[name$=_addressFields_country]').change(function () {
        const $form = $(this).parents('form');
        const currentCountry = $(this).val();
        // Zip code
        const zipCode = $form.find('input[name$=_postalCode]')[0];
        if (zipCode.value.trim() != ''){
            checkFieldValidity.call(zipCode);
        }

        if ($form.find('select[name$=_states_stateCode]').length > 0) {
            // Update states codes required attribute
            const countryStateAttributes = statesCodes[currentCountry] || statesCodes.default;
            // Clear current options
            $('select#billingState[name$=_states_stateCode]').empty();

            if (countryStateAttributes.statesCodes) {
                $form.find('select[name$=_states_stateCode]').removeAttr('disabled');
                if (countryStateAttributes.mandatory) {
                    $form.find('select[name$=_states_stateCode]').attr('required', 'required');
                    $form.find('select[name$=_states_stateCode]').attr('aria-required', 'true');
                    $('select#billingState[name$=_states_stateCode]').siblings().find('.billing-state-mandatory-star').removeClass('d-none');
                } else {
                    $form.find('select[name$=_states_stateCode]').removeAttr('required');
                    $form.find('select[name$=_states_stateCode]').attr('aria-required', 'false');
                    $('select#billingState[name$=_states_stateCode]').siblings().find('.billing-state-mandatory-star').addClass('d-none');
                }

                // Add default options
                $('select#billingState[name$=_states_stateCode]').append($('<option>', {
                    value: null,
                    text: ''
                }));
                // Add state options
                for (var i = 0; i < countryStateAttributes.statesCodes.length; i++) {
                    $('select#billingState[name$=_states_stateCode]').append($('<option>', {
                        value: countryStateAttributes.statesCodes[i].id,
                        text: countryStateAttributes.statesCodes[i].displayValue
                    }));
                }
            } else {
                // Chose default value and make it non mandatory
                $('select#billingState[name$=_states_stateCode]').prop("selectedIndex", 0);
                $form.find('select[name$=_states_stateCode]').attr('disabled', true);
                $form.find('select[name$=_states_stateCode]').removeAttr('required');
                $('select#billingState[name$=_states_stateCode]').siblings().find('.billing-state-mandatory-star').addClass('d-none');
            }

            checkFieldValidity.call($form.find('select[name$=_states_stateCode]')[0]);
        }
    });

    $('body').one('forms:render', function () {
        // add listener to re-initalize form validation, useful when new forms are injected by
        // script from another cartridge (for example, CORE cartridge)
        init();
    });
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

const fieldCustomValidation = [
    {
        fieldSelector: '[data-pattern]',
        errorMessage: 'pattern-mismatch',
        isValid($field) {
            const currentValue = $field.val();
            // don't show error if field is empty but not required
            if (!currentValue && !$field.prop('required')) {
                return true;
            }

            const regexPattern = $field.data('pattern');

            if (regexPattern) {
                const isValid = new RegExp(regexPattern).test($field.val());

                if (!isValid) {
                    $field[0].setCustomValidity($field.data(this.errorMessage));
                    $field.trigger('invalid');
                }

                return isValid;
            }
            return true;
        }
    },
    {
        fieldSelector: 'input[name$=_postalCode]',
        errorMessage: 'pattern-mismatch',
        isValid($field) {
            const $form = $field.closest('form');
            const currentCountry = $form.find('select[name$=_country]').val();
            const countryCodes = libPostalCodes[currentCountry] || libPostalCodes.default;

            let result = false;

            if (countryCodes) {
                const regEx = new RegExp(countryCodes.pattern);
                const message = $field.val();
                result = regEx.test(message.trim());
                if (!result) {
                    $field[0].setCustomValidity($field.data(this.errorMessage));
                    $field.trigger('invalid');

                    return result;
                }
            }

            // Specific code for shipping form
            if ($form.hasClass('shipping-form')) {
                if (isZipCodeExcluded($field.val())) {
                    $field[0].setCustomValidity($field.data(this.errorMessage));
                    $field.trigger('invalid');
                    // Force error message specific for excluded zipCode
                    $field.parents('.form-group').find('.invalid-feedback')
                        .text($('#shippingErrorExcludedPostalCode').html()).attr('role', 'alert');
                    return false;
                }
            }

            if (!result) {
                $field[0].setCustomValidity($field.data(this.errorMessage));
                $field.trigger('invalid');
            }

            return result;
        }
    },
    {
        fieldSelector: 'input[name$=_emailconfirm]',
        errorMessage: 'pattern-mismatch',
        isValid($field) {
            const currentValue = $field.val();
            // don't show error if field is empty but not required
            if (!currentValue) {
                return;
            }

            if ($('input[name$=email]').val() !== currentValue) {
                $field[0].setCustomValidity($field.data(this.errorMessage));
                $field.trigger('invalid');
                return false;
            }
            return true;
        }
    },
    {
        fieldSelector: 'input[name$=_newpasswordconfirm], input[name$=_passwordconfirm]',
        errorMessage: 'pattern-mismatch',
        isValid($field) {
            const currentValue = $field.val();
            // don't show error if field is empty but not required
            if (!currentValue) {
                return;
            }

            if ($('input[name$=_newpassword], input[name$=_password]').val() !== currentValue) {
                $field[0].setCustomValidity($field.data(this.errorMessage));
                $field.trigger('invalid');
                return false;
            }
            return true;
        }
    },
    {
        fieldSelector: 'input[name$=_birthday]',
        errorMessage: 'legal-age-error',
        isValid($field) {
            const currentValue = $field.val();

            // don't show error if field is empty but not required
            if (!currentValue && !$field.prop('required')) {
                return true;
            }

            // Check only if match regex
            if (currentValue.match($field.data('pattern'))) {
                var customerAge = new Date(currentValue);
                if ($field.data('date-pattern')) {
                    if ($field.data('date-pattern') === 'DD/MM/YYYY') {
                        var splitDate = currentValue.split('/');
                        customerAge = new Date(parseInt(splitDate[2], 10), parseInt(splitDate[1] - 1, 10), parseInt(splitDate[0], 10));
                    }
                }

                var legalAge = new Date();
                legalAge.setFullYear(legalAge.getFullYear() - 16);

                if (legalAge <= customerAge) {
                    // Customer doesn't have 16 years
                    $field[0].setCustomValidity($field.data(this.errorMessage));
                    $field.trigger('invalid');
                    return false;
                }
            }
            return true;
        }
    },
    {
        fieldSelector: 'input[name$=_expirationMonthYear]',
        errorMessage: 'pattern-mismatch',
        isValid($field) {
            var currentValue = $field.val();
            if (currentValue) {
                var splitDate = currentValue.split('/');
                var dateNow = new Date();
                var currentDate = new Date(dateNow.getFullYear(), dateNow.getMonth());
                var cardExpirationDate = new Date(currentDate.getFullYear().toString().substring(0,2) + splitDate[1], parseInt(splitDate[0] - 1));
                if (cardExpirationDate < currentDate) {
                    $field[0].setCustomValidity($field.data(this.errorMessage));
                    $field.trigger('invalid');
                    return false;
                }
            }
            return true;
        }
    },
    {
        fieldSelector: 'input[name$=_optinNewsletter_addtosmslist]',
        errorMessage: 'missing-error',
        isValid($field) {
            var $form = $field.parents('form');
            var $phoneField = $form.find('input[name$=_optinNewsletter_phone]');

            if ($phoneField.length > 0) {
                if ($field.is(':checked')) {
                    $phoneField.attr('required','required');
                } else {
                    $phoneField.removeAttr('required');
                }
                checkFieldValidity.call($phoneField[0]);
            }
            return true;
        }
    },
    {
        fieldSelector: 'input[name$=_optinNewsletter_addtoemaillist]',
        errorMessage: 'check-error',
        isValid($field) {
            var $form = $field.parents('form');
            var $emailField = $form.find('input[name$=_optinNewsletter_email]');

            if ($emailField.length > 0) {
                checkFieldValidity.call($emailField[0]);
            }
            return true;
        }
    }
];

/**
 * Handles fields with custom validation
 * @param {JQuery} $field field to validate
 * @returns {boolean} true if valid
 */
function handleFieldCustomValidation($field) {
    for (let i = 0; i < fieldCustomValidation.length; i++) {
        const validation = fieldCustomValidation[i];

        if ($field.is(validation.fieldSelector)) {
            const isValid = validation.isValid($field);

            if (!isValid) {
                return isValid;
            }
        }
    }

    return true;
}

/**
 * Checks the validity of an input
 */
function checkFieldValidity() {
    this.setCustomValidity(''); // Reset custom validation

    const $field = $(this);
    const isValid = this.checkValidity() && handleFieldCustomValidation($field); // Call regular and custom validations

    if (isValid) {
        $field.removeClass('is-invalid').toggleClass('is-valid', Boolean($field.val()));
        $field.parents('.form-group').find('.invalid-feedback').html('').hide();
        $field.parents('.form-group').find('input').attr('aria-invalid', 'false');
        $field.parents('.form-group').find('input').removeClass('is-invalid').addClass('is-valid');
    } else {
        $field.parents('.form-group').find('.invalid-feedback').show();
        $field.parents('.form-group').find('input').attr('aria-invalid', 'true');
        $field.parents('.form-group').removeClass('is-valid').addClass('is-invalid');
        $field.parents('.form-group').find('input').removeClass('is-valid').addClass('is-invalid');
    }
    if ($field.parents('form').hasClass('paymentmethod-form')) {
        updateSumbitPaymentButton($field.parents('form'));
    } else {
        updateSubmitButton($field.parents('form'));
    }
}

function updateSumbitPaymentButton(form) {
    if (form.find('select.is-invalid, input.is-invalid').length > 0) {
        $('button.submit-payment').attr('disabled', true);
    } else {
        var missingRequiredField = false;
        // Check each required field (there are valid if the user hasn't edited it)
        form.find('input,select').filter('[required]:visible:not([readonly])').each(function(){
            if (!$(this).is(':valid')) {
                missingRequiredField = true;
                return;
            }
        });
        if (!missingRequiredField && ($('#legalConditions').length === 0 || $('#legalConditions').is(':checked'))) {
            $('button.submit-payment').attr('disabled', false);
        }
    }
}

function updateSubmitButton(form) {
    // Specific code, only for checkout for the moment
    if ($('.checkout-page, .container-order-confirm-create-account, .page-optin').length > 0) {
        if (form.find('select.is-invalid, input.is-invalid').length > 0) {
            form.find('button:not(.toggle_password)').attr('disabled', true);
            $('#button-billing-cloned').attr('disabled', true);
            $('#js-billing-step-btn .submit-shipping').attr('disabled', true);
            $('#js-billing-step-btn .submit-billing').attr('disabled', true);
        } else {
            var missingRequiredField = false;
            // Check each required field (there are valid if the user hasn't edited it)
            form.find('input,select').filter('[required]:visible:not([readonly])').each(function(){
                if (!$(this).is(':valid')) {
                    missingRequiredField = true;
                    return;
                }
            });
            if (!missingRequiredField) {
                form.find('button:not(.toggle_password)').attr('disabled', false);
                $('#button-billing-cloned').attr('disabled', false);
                $('#js-billing-step-btn .submit-shipping').attr('disabled', false);
                $('#js-billing-step-btn .submit-billing').attr('disabled', false);
            }
        }
    }
}

function isZipCodeExcluded(zipcode) {
    var zipCodeToCheck = zipcode;
    if ($('.shipping-address #shippingCountry, .edit-address-revamp #shippingCountry').val() === 'FR') {
        zipCodeToCheck = zipcode.substring(0, 3);
    }

    var excludedAddresses = $('[name="excludedZipCode"]').val();
    if (excludedAddresses) {
        return excludedAddresses.split(',').includes(zipCodeToCheck);
    }

    return false;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid').removeAttr('aria-invalid');
}

module.exports = {
    invalid: function () {
        $('form input, form select, form textarea').on('invalid', function (e) {
            e.preventDefault();

            // Prevent error message display when user is still writing (due to event on keyup)
            if (!$(this).is(':focus')) {
                if (!this.validity.valid) {
                    var validationMessage = this.validationMessage;
                    $(this).addClass('is-invalid').attr('aria-invalid', 'true');

                    if ((this.validity.patternMismatch || this.validity.customError) &&
                        $(this).data('pattern-mismatch')) {
                        if ($(this).data('password-equal')) {
                            validationMessage = $(this).data('password-equal');
                        } else {
                            validationMessage = $(this).data('pattern-mismatch');
                        }
                    }
                    if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) &&
                        $(this).data('range-error')) {
                        validationMessage = $(this).data('range-error');
                    }
                    if ((this.validity.tooLong || this.validity.tooShort) &&
                        $(this).data('range-error')) {
                        validationMessage = $(this).data('range-error');
                    }
                    if (this.validity.valueMissing && $(this).data('missing-error')) {
                        validationMessage = $(this).data('missing-error');
                    }
                    if (validationMessage === 'undefined') {
                        validationMessage = '';
                    }

                    if ($('.new-password-page').length) {
                        if ($(this).data('password-equal')) {
                            validationMessage = $(this).data('password-equal');
                        } else {
                            validationMessage = $(this).data('pattern-mismatch');
                        }
                    }

                    $(this).parents('.form-group').find('.invalid-feedback')
                        .text(validationMessage).attr('role', 'alert');
                }
            }
        });
    },
    focusin: function() {
        $('form input[type="checkbox"], form select, form textarea').on('change', checkFieldValidity);
    },

    focusout: function() {
        $('form input:not([type="checkbox"]), form select, form textarea').on('focusout', checkFieldValidity);
    },

    keyup: function() {
        $('form input:not([type="checkbox"])').on('keyup', checkFieldValidity);
    },

    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
        });
    },

    legalcheckbox: function () {
        if ($('#legalConditions').length && $('#legalConditions').not(':checked')) {
            $('.paypalDetails, .apple-pay-cart').addClass('paymentButton--disabled')
        }
        $('#legalConditions').on('click', function () {
            if ($('#legalConditions').is(':checked')) {
                $('.paypalDetails, .apple-pay-cart').removeClass('paymentButton--disabled')
            } else {
                $('.paypalDetails, .apple-pay-cart').addClass('paymentButton--disabled')
            }
        });
    },

    setCustomErrorMessages: function () {
        $('form[novalidate]').each(function () {
            var DEFAULT_MISSING_ERROR = $('[name="default-missing-error"]').val();
            var DEFAULT_PARSE_ERROR = $('[name="default-parse-error"]').val();

            $(this).find('input, textarea').each(function (index, elem) { // eslint-disable-line no-unused-vars
                var elemData = $(elem).data();
                var elemRequired = $(elem).is('[required]');
                var elemPatterned = $(elem).is('[pattern]');
                var disabled = $(elem).prop("disabled");
                var elemTyped = /tel|email|number|date/.test($(elem).attr('type'));

                if (disabled) {
                    $(elem).parents('.form-group').find('.icon-CHECK').addClass('d-none');
                    $(elem).addClass('is-invalid').attr('aria-invalid', 'true');
                }
                if (elemRequired && !elemData.missingError) {
                    $(elem).attr('data-missing-error', DEFAULT_MISSING_ERROR);
                }
                if ((elemTyped || elemPatterned) && !elemData.patternMismatch) {
                    $(elem).attr('data-pattern-mismatch', DEFAULT_PARSE_ERROR);
                }
            });
        });
    },
    init,
    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    }
};