'use strict';

module.exports = {
    default: {
        mandatory: true,
        pattern: '^(^\\d{5}(-\\d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\\d{1}[A-Za-z]{1} *\\d{1}[A-Za-z]{1}\\d{1}$)$',
    },
    PL: {
        mandatory: true,
        pattern: '^\\d{2}\\-?\\d{3}$',
    },
    CZ: {
        mandatory: true,
        pattern: '^\\d{3}\\s?\\d{2}$',
    },
    GB: {
        mandatory: true,
        pattern: '^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])[\\s-]{0,1}[0-9][a-zA-Z]{2}$',
    },
    SA: {
        pattern: '^\\d{5}$',
    },
    CN: {
        mandatory: true,
        pattern: '^\\d{6}$',
    },
    JP: {
        mandatory: true,
        pattern: '^\\d{3}-?\\d{4}$',
    },
    FR: {
        mandatory: true,
        pattern: '^\\d{5}$',
    },
    BE: {
        mandatory: true,
        pattern: '^\\d{4}$',
    },
    CY: {
        mandatory: true,
        pattern: '^\\d{4}$',
    },
    DE: {
        mandatory: true,
        pattern: '^\\d{5}$',
    },
    IT: {
        mandatory: true,
        pattern: '^\\d{5}$',
    },
    ES: {
        mandatory: true,
        pattern: '^(?!(38[0-9]{3}))[0-9]{1,5}$',
    },
    NL: {
        mandatory: true,
        pattern: '^\\d{4}\\s*[A-Z]{2}$',
    },
    US: {
        mandatory: true,
        pattern: '^(\\d{5})(?:[\\-](\\d{4}))?$',
    },
    CA: {
        mandatory: true,
        pattern: '^[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z]\\s?\\d[ABCEGHJ-NPRSTV-Z]\\d$',
    }, 
    AU: {
        mandatory: true,
        pattern: '^(ACT|NSW|NT|QLD|SA|TAS|VIC|WA)\\s[0-9]{4}$'
    }
};
