var $ = window.$,
    $window = $(window),
    $body = $('body'),
    $mainHeader = $('header.main-header'),
    mainHeaderHeight = $mainHeader.outerHeight(),
    $countrySelection = $('.country-selection'),
    tabletWidth = 1024,
    borderWidthDesktop = 16,
    borderWidthMobile = 12,
    borderOffset = $window.width() >= tabletWidth ? borderWidthDesktop : borderWidthMobile,
    doSticky = true,
    $addToCart = $('#add-to-cart'),
    $addToCartShortcuts = $('header .add-to-cart'),
    countrySelectionHeight = 0;

$('.share-social-media-dropdown').triggerify({
    trigger: $('.share-social-media .trigger'),
    hover: true,
    position: 'under'
});

$body.triggerify({
    trigger: $('#button-mobile'),
    toggleClass: 'menu-open',
    offDelay: 0
});

$(window).resize(function () {
    $body.data('triggerify').off();
});


// Country selection
if ($countrySelection.length > 0) {
    $countrySelection.triggerify({
        trigger: $('#country-dropdown-button-open, #country-dropdown-button-close, .trigger-countryselector, .country-select'),
        toggleClass: 'open',
        offDelay: 0
    }).on('triggeron', function () {
        $body.data('triggerify').off();
    });

    // fix country selector gap between UL's on mobile
    if ($(window).width() < 1024) {
        const $sectionLeft = $countrySelection.find('.selector-left ul');
        $sectionLeft.eq(0).append($sectionLeft.eq(1).contents());
    }
}

if ($('header.sub-header-checkout').length > 0) {
    doSticky = false;
}
var listHeader = $('.c-list-header');
if (listHeader.length > 0) {
    if ($(window).width() < 1024) {
        listHeader.removeClass('active');
        $('.country-list-close').on('click', function (e) {
            e.stopPropagation();
            listHeader.removeClass('active');
        });
    }
    listHeader.on('click', function (e) {
        e.stopPropagation();
        listHeader.removeClass('active');
        $(this).toggleClass('active');
        if ($(window).width() > 1024) {
            var countrySelectionPopupHeight = $('.country-selection.open').height();
            var countrySelectionFirstSectionHeight = 270;
            $('.country-list.o-list').css('max-height', countrySelectionPopupHeight - countrySelectionFirstSectionHeight + 'px');
        }
    });

    if ($(window).width() > 1024) {
        $('.selector > li').eq(0).addClass('active');
    }
    if ($(window).width() < 1024) {
        listHeader.find('.country-button-close').on('click', function (event) {
            event.stopPropagation();
            listHeader.removeClass('active');
        });
    }
}

function checkCountryDropDown() {
    var $countryOpenBtnLabel = $('#country-dropdown-button-open').find('.label')
    var countryDropdownButtonContents = $countryOpenBtnLabel.text();

    // add break to mobile country selection button (otherwise text will move out the screen)
    if ($(window).width() < 1024) {
        countryDropdownButtonContents = countryDropdownButtonContents.replace(':', ':<br>');
        $countryOpenBtnLabel.html(countryDropdownButtonContents);
    } else {
        countryDropdownButtonContents = countryDropdownButtonContents.replace(':<br>', ':');
        $countryOpenBtnLabel.html(countryDropdownButtonContents);
    }
}
checkCountryDropDown();

// Sticky menu's (header & sub header)
$window.scroll(function () {
    var scrollY = $window.scrollTop();

    countrySelectionHeight = $countrySelection.hasClass('open') ? $countrySelection.height() : 0;

    if (doSticky) {
        if (scrollY > mainHeaderHeight - borderOffset + countrySelectionHeight) {
            $body.addClass('sticky');
        } else {
            $body.removeClass('sticky');
        }
    }

    if ($addToCartShortcuts.length && $addToCart.length) {
        if (scrollY > $addToCart.offset().top) {
            $body.addClass('show-add-to-cart');
        } else {
            $body.removeClass('show-add-to-cart');
        }
    }
});

if ($addToCartShortcuts.length && $addToCart.length) {

    $addToCartShortcuts.click(function (evt) {
        evt.preventDefault();
        $addToCart[0].click();
    });
}

$window.resize(function () {
    mainHeaderHeight = $mainHeader.outerHeight();
    borderOffset = $window.width() >= tabletWidth ? borderWidthDesktop : borderWidthMobile;
    checkCountryDropDown();
});