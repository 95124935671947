
var jQuery = window.$;

(function ( $ ) {

    var Triggerify = function(options) {

        var me = this;

        me.settings = $.extend({
            offDelay: 500,
            position: null,
            showhide: false,
            hover: false,
            setTargetMaxHeightToAuto: false,
            setTargetHeightToAuto: false,
            toggleClass: 'open',
            targetAutoHeight: 0,
            ignoreClick: false,
            beforeUpdate: function() {}
        }, options);

        // console.log(me.settings);

        me.isDirRtl = $('html').attr('dir') === 'rtl' || $('html').hasClass('dir-rtl');

        me.doShowhide = me.settings.showhide;
        me.toggleClass = me.settings.toggleClass;
        me.targetAutoHeight = me.settings.targetAutoHeight;

        me.$target = $(me.settings.target);
        me.$trigger = $(me.settings.trigger);
        me.offDelay = me.settings.offDelay;

        me.isTriggerOver = false;
        me.isTargetOver = false;
        me.isTargetOn = null;

        me.isSuspended = false;

        me.offTimer = null;
    };

    Triggerify.prototype = {

        init: function() {

            var me = this;

            if (!me.settings.ignoreClick) {

                me.$trigger.click($.proxy(me.onTriggerClick, me));
            }

            if (me.settings.hover) {
                if (!me.settings.ignoreClick) {
                    me.$target.click($.proxy(me.onTargetClick, me));
                }
                me.$trigger.hover($.proxy(me.onTriggerHover, me));
                me.$trigger.mouseenter($.proxy(me.onTriggerEnter, me));
                me.$trigger.mouseleave($.proxy(me.onTriggerLeave, me));
                me.$target.hover($.proxy(me.onTargetHover, me));
                me.$target.mouseenter($.proxy(me.onTargetEnter, me));
                me.$target.mouseleave($.proxy(me.onTargetLeave, me));
            }

            if (me.settings.setTargetMaxHeightToAuto || me.settings.setTargetHeightToAuto) {

                me.targetAutoHeight = me.getTargetAutoHeight();

                var timer;

                $(window).resize(function() {

                    clearTimeout(timer);
                    timer = setTimeout(function() {
                        me.targetAutoHeight = me.getTargetAutoHeight();
                    }, 500);
                });
            }

            me.updateState();
        },

        onTriggerClick: function(evt) {

            if (this.isSuspended)
                return;

            if (evt.delegateTarget.tagName === 'A') {
                evt.preventDefault();
            }
            evt.stopImmediatePropagation();
            this.activeTrigger = evt.delegateTarget;
            this.toggleTarget();
        },


        onTriggerHover: function(evt) {

            if (this.isSuspended)
                return;

            if (this.settings.beforeUpdate() === false) {
                return false;
            }

            this.activeTrigger = evt.delegateTarget;
            this.isTriggerOver = true;
            this.updateState();
        },


        onTriggerEnter: function(evt) {

            if (this.isSuspended)
                return;

            if (this.settings.beforeUpdate() === false) {
                return false;
            }

            this.activeTrigger = evt.delegateTarget;
            this.isTriggerOver = true;
            this.updateState();
        },


        onTriggerLeave: function() {

            if (this.isSuspended)
                return;

            var me = this;
            me.isTriggerOver = false;
            clearTimeout(me.offTimer);
            me.offTimer = setTimeout(function() {
                me.updateState();
            }, me.offDelay);
        },


        onTargetClick: function() {

            if (this.isSuspended)
                return;

            this.toggleTarget();
        },


        onTargetHover: function() {

            if (this.isSuspended)
                return;

            this.isTargetOver = true;
            this.updateState();
        },


        onTargetEnter: function() {

            if (this.isSuspended)
                return;

            this.isTargetOver = true;
            this.updateState();
        },


        onTargetLeave: function() {

            if (this.isSuspended)
                return;

            var me = this;
            me.isTargetOver = false;
            clearTimeout(me.offTimer);
            me.offTimer = setTimeout(function() {
                me.updateState();
            }, me.offDelay);
        },


        suspend: function(suspend) {

            this.isSuspended = suspend === undefined || !!suspend;

            if (this.isSuspended) {
                this.on(false);
            } else {
                this.updateState();
            }
        },


        activate: function(activate) {

            this.suspend(activate === false || false);
        },


        isRadioTrigger: function() {

            return this.$trigger[0]
                && this.$trigger[0].tagName === 'INPUT'
                && this.$trigger[0].type === 'radio';
        },


        isCheckeableTrigger: function() {

            return this.$trigger[0]
                && this.$trigger[0].tagName === 'INPUT'
                && ['radio', 'checkbox'].indexOf(this.$trigger[0].type);
        },


        isCheckedTrigger: function() {

            return this.$trigger[0] && this.$trigger[0].checked;
        },


        getTargetAutoHeight: function() {

            var me = this,
                autoHeight = 0,
                transition = me.$target[0].style.transition,
                height = me.$target[0].style.height,
                maxHeight = me.$target[0].style.maxHeight;

            me.$target.addClass('no-transition');

            me.$target[0].style.transition = 'all 0 ease 0';
            me.$target[0].style.height = 'auto';
            me.$target[0].style.maxHeight = 'none';

            me.$target[0].offsetHeight; // trigger reflow
            autoHeight = me.$target.outerHeight();

            me.$target[0].style.transition = transition;
            me.$target[0].style.height = height;
            me.$target[0].style.maxHeight = maxHeight;

            me.$target.removeClass('no-transition');

            return autoHeight;
        },


        isOn: function() {

            return this.$target.hasClass(this.toggleClass);
        },


        updateState: function() {

            if (this.isCheckeableTrigger()) {
                this.on(this.isCheckedTrigger());
            } else {
                this.on(this.isTriggerOver || this.isTargetOver);
            }
        },


        toggleTarget: function() {

            if (this.isCheckeableTrigger()) {
                this.on(this.isCheckedTrigger());
            } else {
                this.on(!this.isOn());
            }
        },


        on: function(on) {

            var me = this;

            if (on === false) {
                me.off();
                return;
            }

            if (me.activeTrigger) {
                var text = $(me.activeTrigger).data('targettext');
                if (text) {
                    me.$target.html('<span class="inner">' + text + '</span>');
                }
            }

            if (me.doShowhide) {
                me.$target.show();
            }
            if (me.settings.position) {
                me.doLayout();
            }
            if (me.settings.setTargetMaxHeightToAuto) {
                me.$target.css('max-height', me.targetAutoHeight + 'px');
            }
            if (me.settings.setTargetHeightToAuto) {
                me.$target.css('height', me.targetAutoHeight + 'px');
            }
            me.$trigger.addClass(me.toggleClass);
            me.$target.addClass(me.toggleClass);

            if (me.$trigger[0] && me.$trigger[0].tagName === 'INPUT') {
                me.$trigger.prop('checked', 'checked');
            }

            me.$target.trigger('triggeron');
        },


        off: function() {

            var me = this;

            if (me.doShowhide) {
                me.$target.hide();
            }
            if (me.settings.setTargetMaxHeightToAuto) {
                me.$target.css('max-height', '0px');
            }
            if (me.settings.setTargetHeightToAuto) {
                me.$target.css('height', '');
            }
            me.$trigger.removeClass(me.toggleClass);
            me.$target.removeClass(me.toggleClass);

            if (me.$trigger[0] && me.$trigger[0].tagName === 'INPUT') {
                me.$trigger.prop('checked', false);
            }

            me.$target.trigger('triggeroff');
        },


        doLayout: function() {

            // var pos = this.$trigger.offset();
            var me = this,
                pos = me.$trigger.position(),
                marginTop = parseInt(me.$trigger.css('marginTop')),
                height = me.$trigger.outerHeight(true),
                width = me.$trigger.outerWidth(true),
                x,
                y;

            switch (this.settings.position) {

                case 'under':
                    y = pos.top + height;
                    x = pos.left;
                    me.$target.css({
                        left: x + 'px',
                        top: y + 'px'
                    });
                    break;

                case 'side':
                    y = pos.top + marginTop;
                    x = pos.left + width;
                    me.$target.css({
                        left: x + 'px',
                        top: y + 'px'
                    });
                    break;

            }
        }
    };


    $.fn.triggerify = function( options ) {

        var $trigger;

        if (!options.trigger) {
            //console.log('Error. Please specify trigger option');
        } else if (!options.trigger instanceof jQuery) { // eslint-disable-line no-unsafe-negation
            $trigger = $(options.trigger);
        } else {
            $trigger = options.trigger;
        }

        return this.each(function(index) {

            var elem = this,
                $elem = $(elem),
                trigger = $trigger[index],
                triggerify;

            if (!$elem.data('triggerify')) {

                triggerify  = new Triggerify($.extend({
                    target: $elem,
                    trigger: trigger
                }, options));

                $elem.data('triggerify', triggerify);

                triggerify.init();
            }
        });
    };

}(jQuery));
