window.jQuery = window.$ = require('jquery');
var processInclude = require('./util');
$(document).ready(function() {
    processInclude(require('./components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/radioButtonADA'));
    processInclude(require('./components/accessibilityHelpers'));
    processInclude(require('./components/stickyAddToCart'));
    processInclude(require('./triggerify'));
    processInclude(require('./countrySelector'));
    processInclude(require('./countries'));
    processInclude(require('base/components/toolTip'));

});

require('./thirdParty/bootstrap');
require('./components/spinner');
require('./thirdParty/slick');
require('./thirdParty/owl');

require('jquery-ui/ui/widgets/autocomplete');
