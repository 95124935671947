'use strict';

module.exports = {
    default: {
        mandatory: false
    },
    US: {
        mandatory: true,
        statesCodes: [
            {
                id: "AL",
                displayValue: "Alabama"
            },
            {
                id: "AK",
                displayValue: "Alaska"
            },
            {
                id: "AZ",
                displayValue: "Arizona"
            },
            {
                id: "AR",
                displayValue: "Arkansas"
            },
            {
                id: "CA",
                displayValue: "California"
            },
            {
                id: "CO",
                displayValue: "Colorado"
            },
            {
                id: "CT",
                displayValue: "Connecticut"
            },
            {
                id: "DE",
                displayValue: "Delaware"
            },
            {
                id: "DC",
                displayValue: "District of Columbia"
            },
            {
                id: "FL",
                displayValue: "Florida"
            },
            {
                id: "GA",
                displayValue: "Georgia"
            },
            {
                id: "HI",
                displayValue: "Hawaii"
            },
            {
                id: "ID",
                displayValue: "Idaho"
            },
            {
                id: "IL",
                displayValue: "Illinois"
            },
            {
                id: "IN",
                displayValue: "Indiana"
            },
            {
                id: "IA",
                displayValue: "Iowa"
            },
            {
                id: "KS",
                displayValue: "Kansas"
            },
            {
                id: "KY",
                displayValue: "Kentucky"
            },
            {
                id: "LA",
                displayValue: "Louisiana"
            },
            {
                id: "ME",
                displayValue: "Maine"
            },
            {
                id: "MD",
                displayValue: "Maryland"
            },
            {
                id: "MA",
                displayValue: "Massachusetts"
            },
            {
                id: "MI",
                displayValue: "Michigan"
            },
            {
                id: "MN",
                displayValue: "Minnesota"
            },
            {
                id: "MS",
                displayValue: "Mississippi"
            },
            {
                id: "MO",
                displayValue: "Missouri"
            },
            {
                id: "MT",
                displayValue: "Montana"
            },
            {
                id: "NE",
                displayValue: "Nebraska"
            },
            {
                id: "NV",
                displayValue: "Nevada"
            },
            {
                id: "NH",
                displayValue: "New Hampshire"
            },
            {
                id: "NJ",
                displayValue: "New Jersey"
            },
            {
                id: "NM",
                displayValue: "New Mexico"
            },
            {
                id: "NY",
                displayValue: "New York"
            },
            {
                id: "NC",
                displayValue: "North Carolina"
            },
            {
                id: "ND",
                displayValue: "North Dakota"
            },
            {
                id: "OH",
                displayValue: "Ohio"
            },
            {
                id: "OK",
                displayValue: "Oklahoma"
            },
            {
                id: "OR",
                displayValue: "Oregon"
            },
            {
                id: "PA",
                displayValue: "Pennsylvania"
            },
            {
                id: "RI",
                displayValue: "Rhode Island"
            },
            {
                id: "SC",
                displayValue: "South Carolina"
            },
            {
                id: "SD",
                displayValue: "South Dakota"
            },
            {
                id: "TN",
                displayValue: "Tennessee"
            },
            {
                id: "TX",
                displayValue: "Texas"
            },
            {
                id: "UT",
                displayValue: "Utah"
            },
            {
                id: "VT",
                displayValue: "Vermont"
            },
            {
                id: "VA",
                displayValue: "Virginia"
            },
            {
                id: "WA",
                displayValue: "Washington"
            },
            {
                id: "WV",
                displayValue: "West Virginia"
            },
            {
                id: "WI",
                displayValue: "Wisconsin"
            },
            {
                id: "WY",
                displayValue: "Wyoming"
            }
        ]
    },
    CA: {
        mandatory: true,
        statesCodes: [
            {
                id: "AB",
                displayValue: "Alberta"
            },
            {
                id: "BC",
                displayValue: "British Columbia"
            },
            {
                id: "MB",
                displayValue: "Manitoba"
            },
            {
                id: "NB",
                displayValue: "New Brunswick"
            },
            {
                id: "NL",
                displayValue: "Newfoundland and Labrado"
            },
            {
                id: "NS",
                displayValue: "Nova Scotia"
            },
            {
                id: "NT",
                displayValue: "Northwest Territories"
            },
            {
                id: "NU",
                displayValue: "Nunavut"
            },
            {
                id: "ON",
                displayValue: "Ontario"
            },
            {
                id: "PE",
                displayValue: "Prince Edward Island"
            },
            {
                id: "QC",
                displayValue: "Québec"
            },
            {
                id: "SK",
                displayValue: "Saskatchewan"
            },
            {
                id: "YT",
                displayValue: "Yukon"
            }
        ]
    },
    CN: {
        mandatory: true,
        statesCodes: [
            {
                id: "AH",
                displayValue: "Anhui"
            },
            {
                id: "BJ",
                displayValue: "Beijing"
            },
            {
                id: "CQ",
                displayValue: "Chongqing"
            },
            {
                id: "FJ",
                displayValue: "Fujian"
            },
            {
                id: "GD",
                displayValue: "Guangdong"
            },
            {
                id: "GS",
                displayValue: "Gansu"
            },
            {
                id: "GZ",
                displayValue: "Guizhou"
            },
            {
                id: "HA",
                displayValue: "Henan"
            },
            {
                id: "HB",
                displayValue: "Hubei"
            },
            {
                id: "HE",
                displayValue: "Hebei"
            },
            {
                id: "HI",
                displayValue: "Hainan"
            },
            {
                id: "HL",
                displayValue: "Heilongjiang"
            },
            {
                id: "HU",
                displayValue: "Hunan"
            },
            {
                id: "JL",
                displayValue: "Jilin"
            },
            {
                id: "JS",
                displayValue: "Jiangsu"
            },
            {
                id: "JX",
                displayValue: "Jiangxi"
            },
            {
                id: "LN",
                displayValue: "Liaoning"
            },
            {
                id: "QH",
                displayValue: "Qinghai"
            },
            {
                id: "SC",
                displayValue: "Sichuan"
            },
            {
                id: "SD",
                displayValue: "Shandong"
            },
            {
                id: "SH",
                displayValue: "Shanghai"
            },
            {
                id: "SN",
                displayValue: "Shaanxi"
            },
            {
                id: "SX",
                displayValue: "Shanxi"
            },
            {
                id: "TJ",
                displayValue: "Tianjin"
            },
            {
                id: "YN",
                displayValue: "Yunnan"
            },
            {
                id: "ZJ",
                displayValue: "Zhejiang"
            }
        ]
    }
};