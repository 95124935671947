'use strict';

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    var urlContent = $('.tracking-consent').data('url');
    var urlAccept = $('.tracking-consent').data('accept');
    var textHeader = $('.tracking-consent-content').data('heading-content');
    var close = $('.tracking-consent').data('close');
    var htmlString = '<div class="cookie-bar" role="banner" aria-label="Cookie" id="consent-tracking" style="display: block;">' +
        '<p>' +
        textHeader +
        '</p>' +
        '<button type="button" class="btn btn-close btn-close-invert affirm" data-url="' + urlAccept + '" aria-label="Close the cookie banner"> '+ close +'</button>' +
        '</div>';
    $.spinner().start();
    $('#consent-tracking').focus();
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function () {
            $('.veil').remove();
        },
        error: function () {
            $('#consent-tracking').remove();
        }
    });

    $('#consent-tracking button').click(function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('#top').focus();
                $('#consent-tracking .modal-body').remove();
                $('#consent-tracking').remove();
                $.spinner().stop();
            },
            error: function () {
                $('#consent-tracking .modal-body').remove();
                $('#consent-tracking').remove();
                $.spinner().stop();
            }
        });
    });

    $('.mouse-navigation').focusout();
    $('.mouse-navigation').blur();
    if($('#consent-tracking').length > 0) $('#consent-tracking').attr("tabindex",-1).focus();
    if($('.optanon-alert-box-wrapper').length > 0) $('.optanon-alert-box-wrapper').attr("tabindex",-1).focus();
}

module.exports = function () {
    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')) {
        showConsentModal();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            showConsentModal();
        });
    }
};